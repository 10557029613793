import React, { useState, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const initialProducts = [
  {
    id: 1,
    name: 'AWS Hosting',
    actuals: {
      Jan: 100,
      Feb: 130,
      Mar: 200,
      Apr: 220,
      May: 280,
      Jun: 320,
      Jul: null,
      Aug: null,
      Sep: null,
      Oct: null,
      Nov: null,
      Dec: null,
    },
    forecasts: {
      Jan: null,
      Feb: null,
      Mar: null,
      Apr: null,
      May: null,
      Jun: null,
      Jul: 360,
      Aug: 380,
      Sep: 400,
      Oct: 420,
      Nov: 450,
      Dec: 470,
    },
  },
  {
    id: 2,
    name: 'Rent',
    actuals: {
      Jan: 150,
      Feb: 180,
      Mar: 220,
      Apr: 250,
      May: 300,
      Jun: 330,
      Jul: null,
      Aug: null,
      Sep: null,
      Oct: null,
      Nov: null,
      Dec: null,
    },
    forecasts: {
      Jan: null,
      Feb: null,
      Mar: null,
      Apr: null,
      May: null,
      Jun: null,
      Jul: 360,
      Aug: 390,
      Sep: 410,
      Oct: 430,
      Nov: 460,
      Dec: 480,
    },
  },
];

const CaratRenderer = (props) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpansion = () => {
    setExpanded(!expanded);
    props.node.setExpanded(!expanded);
  };

  return (
    <div className="carat-cell">
      <span className={`carat ${expanded ? 'expanded' : ''}`} onClick={toggleExpansion}>
        ▶
      </span>
    </div>
  );
};

const Expenses = () => {
  const [data, setData] = useState(initialProducts);
  const months = Object.keys(data[0].actuals);

  const DropdownCellRenderer = (props) => {
    const [selectedValue, setSelectedValue] = useState('');

    const handleDropdownChange = (event) => {
      setSelectedValue(event.target.value);
    };

    return (
      <select value={selectedValue} onChange={handleDropdownChange}>
        {props.data.forecasts.map((item, index) => (
          <option key={index} value={item.forecast}>
            {item.forecast}
          </option>
        ))}
      </select>
    );
  };

  const columnDefs = [
    {
      headerName: '',
      cellRendererFramework: CaratRenderer,
      width: 40,
    },
    { headerName: 'Category', field: 'name', width: 150 },
    ...months.map((month) => ({
      headerName: month,
      field: `${month}`,
      width: 100,
      editable: true, // Make the cells editable
      cellEditor: 'agTextCellEditor', // Use text editor for editing
    })),
  ];

  const onCellValueChanged = (event) => {
    const { data: editedRow } = event;
    const { name, forecasts, ...actuals } = editedRow;
    const newData = data.map((row) =>
      row.name === name
        ? {
            ...row,
            actuals: { ...row.actuals, ...actuals },
          }
        : row
    );
    setData(newData);
  };

  const rowData = data.map((product) => ({
    name: product.name,
    ...product.actuals,
    forecasts: product.forecasts,
  }));

  const gridOptions = {
    masterDetail: true,
    detailCellRendererParams: {
      // ... rest of your code ...
    },
    frameworkComponents: {
      caratRenderer: CaratRenderer, // Register the custom carat renderer
    },
  };

  const [chartData, setChartData] = useState([]);
  const calculatedChartData = useMemo(() => {
    return months.map((month) => {
      var actualTotal = data.reduce(
        (total, item) => total + (item.actuals[month] || -1),
        0
      );
      var forecastTotal = data.reduce(
        (total, item) => total + (item.forecasts[month] || -1),
        0
      );

      if (actualTotal < 0) {
        actualTotal = null;
      }

      if (forecastTotal < 0) {
        forecastTotal = null;
      }

      return {
        name: month,
        actualTotal,
        forecastTotal,
      };
    });
  }, [data]);

  useEffect(() => {
    setChartData(calculatedChartData);
  }, [calculatedChartData, data]);

  return (
    <Box>
      <Paper elevation={3} style={{ padding: '16px' }}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={chartData} margin={{ right: 30 }}>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="actualTotal"
              stroke="#8884d8"
              name="Actual Total"
            />
            <Line
              type="monotone"
              dataKey="forecastTotal"
              stroke="#82ca9d"
              strokeDasharray="3 3"
              name="Forecast Total"
            />
          </LineChart>
        </ResponsiveContainer>
      </Paper>
      <Paper elevation={3} style={{ padding: '16px', marginTop: '16px' }}>
        <div className="ag-theme-alpine" style={{ height: 300, width: '100%' }}>
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            gridOptions={gridOptions}
            onCellValueChanged={onCellValueChanged}
          />
        </div>
      </Paper>
    </Box>
  );
};

export default Expenses;
