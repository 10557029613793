import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import { API, Auth } from 'aws-amplify';

import { TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import Grid from '@material-ui/core/Grid';

import React, { useState } from 'react';
import { ComposedChart, LineChart, Line, XAxis, YAxis, CartesianGrid, PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer, Bar, BarChart, AreaChart, Area, defs, pattern, stop } from 'recharts';
import './Home.css'; // Import the CSS file for styling

const Box = ({ title, value }) => (
  <div className="box">
    <h2>{title}</h2>
    <p>{value}</p>
  </div>
);

const BiggerBox = ({ children }) => <div className="bigger-box">{children}</div>;

const Home = () => {
  // Sample data for financial metrics
  const financialMetricsData = {
    arr: 1000000,
    mrr: 90000,
    growthRate: 15,
    churnRate: 5,
    revenueAndExpenses: [
      { month: 'Jan', revenue: 50000, expenses: 20000 },
      { month: 'Feb', revenue: 60000, expenses: 25000 },
      { month: 'Mar', revenue: 75000, expenses: 30000 },
      { month: 'Apr', revenue: 90000, expenses: 35000 },
      { month: 'May', revenue: 95000, expenses: 40000 },
      { month: 'Jun', revenue: 110000, expenses: 45000 },
      { month: 'Jul', revenue: 120000, expenses: 50000 },
    ],
    revenueData: [
     { month: 'Jan', actual: 50000, forecast: null },
     { month: 'Feb', actual: 60000, forecast: null },
     { month: 'Mar', actual: 75000, forecast: null },
     { month: 'Apr', actual: null, forecast: 90000 },
     { month: 'May', actual: null, forecast: 95000 },
     { month: 'Jun', actual: null, forecast: 110000 },
     { month: 'Jul', actual: null, forecast: 130000 },
   ],
   subscriptionData: [
     { month: 'Jan', starterActual: 1000, starterForecast: null, proActual: 2000, proForecast: null, enterpriseActual: 3000, enterpriseForecast: null },
     { month: 'Feb', starterActual: 1100, starterForecast: null, proActual: 2100, proForecast: null, enterpriseActual: 3100, enterpriseForecast: null },
     { month: 'Mar', starterActual: 1200, starterForecast: null, proActual: 2200, proForecast: null, enterpriseActual: 3200, enterpriseForecast: null },
     { month: 'Apr', starterActual: null, starterForecast: 1300, proActual: null, proForecast: 2300, enterpriseActual: null, enterpriseForecast: 3300 },
     { month: 'May', starterActual: null, starterForecast: 1400, proActual: null, proForecast: 2400, enterpriseActual: null, enterpriseForecast: 3400 },
     { month: 'Jun', starterActual: null, starterForecast: 1500, proActual: null, proForecast: 2500, enterpriseActual: null, enterpriseForecast: 3500 },
     { month: 'Jul', starterActual: null, starterForecast: 1600, proActual: null, proForecast: 2600, enterpriseActual: null, enterpriseForecast: 3600 },
   ],
   totalSubscribers: [
     { month: 'Jan', value: 6000 },
     { month: 'Feb', value: 6300 },
     { month: 'Mar', value: 6600 },
     { month: 'Apr', value: 6900 },
     { month: 'May', value: 7200 },
     { month: 'Jun', value: 7500 },
     { month: 'Jul', value: 7800 },
   ],
    runwayData: [
      { month: 'Jan', value: 200000 },
      { month: 'Feb', value: 180000 },
      { month: 'Mar', value: 150000 },
      { month: 'Apr', value: 130000 },
      { month: 'May', value: 110000 },
      { month: 'Jun', value: 80000 },
      { month: 'Jul', value: 60000 },
    ],
    newCustomers: 30,
    totalCustomers: 500,
  };

  const monthToDateRevenueData = {
    actual: 42000, // Replace with actual month-to-date revenue
    forecast: 45000, // Replace with forecasted month-to-date revenue
  };

  const revenuePieChartData = [
    { name: 'Month to date Revenue', value: monthToDateRevenueData.actual },
    { name: 'Forecasted Revenue', value: Math.max(0, monthToDateRevenueData.forecast - monthToDateRevenueData.actual) },
  ];

  const monthToDateExpensesData = {
    actual: 40000, // Replace with actual month-to-date expenses
    forecast: 50000, // Replace with forecasted month-to-date expenses
  };

  const expensesPieChartData = [
    { name: 'Month to date Expenses', value: monthToDateExpensesData.actual },
    { name: 'Forecasted Expenses', value: Math.max(0, monthToDateExpensesData.forecast - monthToDateExpensesData.actual) },
  ];

  const COLORS = ['#82ca9d', '#ffb74d'];

  // Formatter function to convert numbers to "k" abbreviation
  const formatNumberToK = (number) => {
    if (number >= 1000) {
      return (number / 1000).toFixed(1) + 'k';
    }
    return number;
  };

  // Custom pattern for the revenueforecast bars
  const revenueForecastPattern = (
    <pattern id="revenue-forecast-pattern" width="6" height="6" patternUnits="userSpaceOnUse" patternTransform="rotate(45)">
      <line x1="0" y1="0" x2="0" y2="6" style={{ stroke: '#82ca9d', strokeWidth: 7 }} />
    </pattern>
  );

  // Custom pattern for the forecast bars
  const forecastPatternStarter = (
    <pattern id="forecast-pattern-starter" width="6" height="6" patternUnits="userSpaceOnUse" patternTransform="rotate(45)">
      <line x1="0" y1="0" x2="0" y2="6" style={{ stroke: '#82ca9d', strokeWidth: 7 }} />
    </pattern>
  );

  const forecastPatternPro = (
    <pattern id="forecast-pattern-pro" width="6" height="6" patternUnits="userSpaceOnUse" patternTransform="rotate(45)">
      <line x1="0" y1="0" x2="0" y2="6" style={{ stroke: '#8884d8', strokeWidth: 7 }} />
    </pattern>
  );

  const forecastPatternEnterprise = (
    <pattern id="forecast-pattern-enterprise" width="6" height="6" patternUnits="userSpaceOnUse" patternTransform="rotate(45)">
      <line x1="0" y1="0" x2="0" y2="6" style={{ stroke: '#ffc658', strokeWidth: 7 }} />
    </pattern>
  );

  const formatRevenue = (value, type) => {
    if (value >= 1000) {
      const formattedValue = (value / 1000).toFixed(1);
      return (
        <div className="revenue-value">
          <span style={{ fontSize: '20px', fontWeight: 'bold' }}>$</span>
          <span style={{ fontSize: '25px', fontWeight: 'bold' }}>{formattedValue}</span>
          <span style={{ fontSize: '15px', verticalAlign: 'super' }}>k</span>
          <span style={{ fontSize: '11px' }}>{type === 'MRR' ? '/mo' : '/yr'}</span>
        </div>
      );
    }
    return <div className="revenue-value">${value}{type === 'MRR' ? '/mo' : '/yr'}</div>;
  };

  const [selectedScenario, setSelectedScenario] = useState('revenueData');
  const handleScenarioChange = (event) => {
    setSelectedScenario(event.target.value);
  };

  return (
    <div className="dashboard">
        <div className="top-bar">
          <FormControl style={{ width: '150px'}} sx={{ minWidth: '20%' }}>
            <InputLabel id="scenario-label">Scenario</InputLabel>
            <Select
              labelId="scenario-label"
              id="scenario-select"
              value={selectedScenario}
              label="Scenario"
              onChange={handleScenarioChange}
            >
              <MenuItem value="revenueData">Scenario A</MenuItem>
              <MenuItem value="runwayData">Scenario B</MenuItem>
            </Select>
          </FormControl>
        </div>
      {/* Top row */}
      <div className="row">
        <div className="box">
          <span style={{ fontSize: '20px', fontWeight: 'bold', color: '#165a72' }}>ARR</span>
          <div className="value-container">
            {formatRevenue(financialMetricsData.arr, 'ARR')}
          </div>
        </div>
        <div className="box">
          <span style={{ fontSize: '20px', fontWeight: 'bold', color: '#165a72' }}>MRR</span>
          <div className="value-container">
            {formatRevenue(financialMetricsData.mrr, 'MRR')}
          </div>
        </div>
        <div className="box">
          <span style={{ fontSize: '20px', fontWeight: 'bold', color: '#165a72' }}>Growth Rate</span>
            <div className="value-container">
              <span style={{ fontSize: '25px', fontWeight: 'bold' }}>{financialMetricsData.growthRate}</span>
              <span style={{ fontSize: '15px' }}>%</span>
            </div>
        </div>
        <div className="box">
          <span style={{ fontSize: '20px', fontWeight: 'bold', color: '#165a72' }}>Churn Rate</span>
            <div className="value-container">
              <span style={{ fontSize: '25px', fontWeight: 'bold' }}>{financialMetricsData.churnRate}</span>
              <span style={{ fontSize: '15px' }}>%</span>
            </div>
        </div>
      </div>

      {/* Second row */}
      <div className="row">
        <BiggerBox>
          <div style={{ width: '100%', height: '300px' }}>
            <h3 style={{ color: '#165a72', marginBottom: '10px', marginTop: '10px' }}>Runway</h3>
            <ResponsiveContainer width="100%" height="90%">
              <AreaChart data={financialMetricsData.runwayData}>
                <defs>
                  <linearGradient id="revenueGradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#007bff" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#007bff" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="month" />
                <YAxis tickFormatter={formatNumberToK} />
                <YAxis tickFormatter={formatNumberToK} />
                <Tooltip />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="value"
                  name="Revenue"
                  stroke="#007bff"
                  fill="url(#revenueGradient)" // Use the defined gradient for fill
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </BiggerBox>
      </div>

      {/* Third row */}
      <div className="row">
        <div className="chart-box" style={{ width: '33%' }}>
          <div style={{ width: '100%', height: '300px' }}>
            <h3 style={{ color: '#165a72', marginBottom: '10px', marginTop: '10px' }}>Income vs Expenses</h3>
            <ResponsiveContainer width="100%" height={270}>
              <BarChart data={financialMetricsData.revenueAndExpenses}>
                <XAxis dataKey="month" />
                <YAxis tickFormatter={formatNumberToK} />
                <Tooltip />
                <Legend />
                <Bar dataKey="revenue" name="Revenue" fill="#82ca9d" /> {/* Lighter shade of green for revenue */}
                <Bar dataKey="expenses" name="Expenses" fill="#f08080" /> {/* Lighter shade of red for expenses */}
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="chart-box" style={{ width: '33%' }}>
          <div style={{ width: '100%', height: '300px' }}>
            <h3 style={{ color: '#165a72', marginBottom: '10px', marginTop: '10px' }}>Revenue (Actuals and Forecast)</h3>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={financialMetricsData.revenueData}>
                <defs>
                  {revenueForecastPattern}
                </defs>
                <XAxis dataKey="month" />
                <YAxis tickFormatter={formatNumberToK} />
                <Tooltip />
                  <Legend
                    layout="horizontal"
                    verticalAlign="bottom"
                    height={50}
                    wrapperStyle={{ overflowX: 'auto' }}
                    payload={
                      [
                        { value: 'Revenue', type: 'rect', id: 'actual-starter', color: '#82ca9d' },
                      ]
                    }
                  />
                <Bar dataKey="actual" name="Actual" fill="#82ca9d" />
                <Bar dataKey="forecast" name="Forecast" fill="url(#revenue-forecast-pattern)" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="chart-box" style={{ width: '33%' }}>
          <div style={{ width: '100%', height: '300px' }}>
            <h3 style={{ color: '#165a72', marginBottom: '10px', marginTop: '10px' }}>Subscribers (Actuals and Forecast)</h3>
              <ResponsiveContainer width="100%" height={300}>
              <BarChart data={financialMetricsData.subscriptionData}>
                {/* Define the pattern outside the ResponsiveContainer */}
                <defs>
                  {forecastPatternStarter}
                  {forecastPatternPro}
                  {forecastPatternEnterprise}
                </defs>
                <XAxis dataKey="month" />
                <YAxis tickFormatter={formatNumberToK} />
                <Tooltip />
                <Legend
                  layout="horizontal"
                  verticalAlign="bottom"
                  height={50}
                  wrapperStyle={{ overflowX: 'auto' }}
                  payload={
                    [
                      { value: 'Starter', type: 'rect', id: 'actual-starter', color: '#82ca9d' },
                      { value: 'Pro', type: 'rect', id: 'actual-pro', color: '#8884d8' },
                      { value: 'Enterprise', type: 'rect', id: 'actual-enterprise', color: '#ffc658' },
                    ]
                  }
                />
                <Bar dataKey="starterActual" name="Starter (Actual)" stackId="actuals" fill="#82ca9d" />
                <Bar dataKey="proActual" name="Pro (Actual)" stackId="actuals" fill="#8884d8" />
                <Bar dataKey="enterpriseActual" name="Enterprise (Actual)" stackId="actuals" fill="#ffc658" />
                {/* Add forecast bars with pattern */}
                <Bar dataKey="starterForecast" name="Starter (Forecast)" stackId="forecasts" fill="url(#forecast-pattern-starter)" />
                <Bar dataKey="proForecast" name="Pro (Forecast)" stackId="forecasts" fill="url(#forecast-pattern-pro)" />
                <Bar dataKey="enterpriseForecast" name="Enterprise (Forecast)" stackId="forecasts" fill="url(#forecast-pattern-enterprise)" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      {/* Fourth row */}
      <div className="row">
        <div className="chart-box" style={{ width: '50%' }}>
          <h3 style={{ color: '#165a72', marginBottom: '10px', marginTop: '10px' }}>Month-to-date Revenue Progress</h3>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={revenuePieChartData}
                dataKey="value"
                cx="50%"
                cy="50%"
                outerRadius={80}
                innerRadius={50}
                startAngle={90}
                endAngle={-270}
                paddingAngle={2}
                label
              >
                {revenuePieChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
              <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle" fill="#000">
                ${monthToDateRevenueData.actual} {/* Display actual revenue in the center */}
              </text>
              <Legend verticalAlign="bottom" align="center" />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="chart-box" style={{ width: '50%' }}>
          <h3 style={{ color: '#165a72', marginBottom: '10px', marginTop: '10px' }}>Month-to-date Expenses</h3>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={expensesPieChartData}
                dataKey="value"
                cx="50%"
                cy="50%"
                outerRadius={80}
                innerRadius={50}
                startAngle={90}
                endAngle={-270}
                paddingAngle={2}
                label
              >
                {expensesPieChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
              <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle" fill="#000">
                ${monthToDateRevenueData.actual} {/* Display actual revenue in the center */}
              </text>
              <Legend verticalAlign="bottom" align="center" />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default Home;
