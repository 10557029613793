import React, { useState, useEffect } from 'react';

const Fundraising = () => {
  return(
    <div>
      Fundraising
    </div>
  )
}

export default Fundraising;
