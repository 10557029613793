import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell, Legend } from 'recharts';
import { Paper } from '@mui/material';

const employeeGrowthData = [
  { month: 'Jan', growth: 10 },
  { month: 'Feb', growth: 15 },
  { month: 'Mar', growth: 20 },
  { month: 'Apr', growth: 22 },
  { month: 'May', growth: 23 },
  { month: 'Jun', growth: 25 },
  { month: 'Jul', growth: 25 },
  { month: 'Aug', growth: 28 },
  // ... other months
];

const jobFunctionSplitData = [
  { name: 'Engineering', jobFunction: 'Engineering', count: 15 },
  { name: 'Sales', jobFunction: 'Sales', count: 6 },
  { name: 'Marketing', jobFunction: 'Marketing', count: 7 },
  // ... other job functions
];

const Employees = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: '20px' }}>
      {/* Graph 1: Growth of Employees */}
      <Paper elevation={3} style={{ flex: 1, padding: '20px', borderRadius: '5px' }}>
        <h2>Growth of Employees</h2>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={employeeGrowthData}>
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="growth" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      </Paper>

      {/* Graph 2: Split by Job Function */}
      <Paper elevation={3} style={{ flex: 1, padding: '20px', borderRadius: '5px' }}>
        <h2>Split by Job Function</h2>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie data={jobFunctionSplitData} dataKey="count" cx="50%" cy="50%" outerRadius={80} label>
              {jobFunctionSplitData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={`#${((1 << 24) * Math.random() | 0).toString(16)}`} />
              ))}
            </Pie>
            <Tooltip />
            <Legend verticalAlign="bottom" />
          </PieChart>
        </ResponsiveContainer>
      </Paper>
    </div>
  );
};

export default Employees;
