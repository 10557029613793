import React, { useState } from "react";
import { Routes, Route, Link } from "react-router-dom";
import "./App.css";
import Expenses from "./pages/Expenses";
import Home from "./pages/Home";
import Integrations from "./pages/Settings.tsx";
import Revenue from "./pages/Revenue";
import Employees from "./pages/Employees";
import Fundraising from "./pages/Fundraising";

import { Amplify, Auth, Hub } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsconfig from "./aws-exports";
import awsExports from './aws-exports';

import {
  Sidebar,
  Menu,
  SubMenu,
  MenuItem,
  SidebarFooter,
  useProSidebar,
} from "react-pro-sidebar";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import SettingsIcon from "@mui/icons-material/Settings";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import PowerIcon from '@mui/icons-material/Power';
import LogoutIcon from '@mui/icons-material/Logout';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';

import PopupModal from './components/FiscaleAIPopup'

Amplify.configure(awsconfig);
Amplify.configure(awsExports);

function App() {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const handleSidebarCollapse = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const { collapseSidebar } = useProSidebar();

  const [isModalOpen, setModalOpen] = useState(false);
  // Function to open the modal
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  async function signOut() {
    try {
        await Auth.signOut();
    } catch (error) {
        console.log('error signing out: ', error);
    }
  }

  return (
    <Authenticator>
      {({ signOut, user }) => (
    <div className={`App ${isSidebarCollapsed ? "collapsed" : ""}`}>
      <Sidebar
        width={isSidebarCollapsed ? "80px" : "250px"}
        collapsed={isSidebarCollapsed}
        backgroundColor='#ffffff'
      >
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Menu>
          {/*TODO: Uncomment once the collapse function is fixed - the main content currently overflows
          <MenuItem
            icon={<MenuOutlinedIcon />}
            onClick={() => {
              handleSidebarCollapse();
              collapseSidebar();
            }}
            style={{ textAlign: "center" }}
          >
          </MenuItem>*/}
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
            <img src={require('./assets/logo.png')} />
            <h2>Fiscale</h2>
          </div>
          <hr />
          <div style={{ flex: 1 }}>
          <MenuItem component={<Link to="/" className="link" />} icon={<HomeOutlinedIcon />}>
            Dashboard
          </MenuItem>
          <MenuItem component={<Link to="/revenue" className="link" />} icon={<TrendingUpIcon />}>
            Revenue
          </MenuItem>
          <MenuItem component={<Link to="/expenses" className="link" />} icon={<ReceiptLongOutlinedIcon />}>
            Expenses
          </MenuItem>
          <MenuItem component={<Link to="/employees" className="link" />} icon={<GroupsOutlinedIcon />}>
            Employees
          </MenuItem>
          <SubMenu icon={<ShareOutlinedIcon />} label="Scenarios">
            <MenuItem icon={<ShareOutlinedIcon />}>Scenario 1</MenuItem>
            <MenuItem icon={<ShareOutlinedIcon />}>Scenario 2</MenuItem>
            <MenuItem icon={<ShareOutlinedIcon />}>Scenario 3</MenuItem>
          </SubMenu>
          <MenuItem component={<Link to="/integrations" className="link" />} icon={<ExtensionOutlinedIcon />}>
            Integrations
          </MenuItem>
          <MenuItem component={<Link to="/fundraising" className="link" />} icon={<MonetizationOnOutlinedIcon />}>
            Fundraising
          </MenuItem>
          </div>
          <div style={{ position: 'absolute', bottom: '0px', width: '100%', flex: 1}}>
          <MenuItem onClick={handleOpenModal} style={{ display: 'flex', position: 'center' }}>
            <img style={{ marginRight: '8px', width: '30px', height: '30px', verticalAlign: 'bottom' }} src={require('./assets/llm.png')} />
            <span style={{ display: 'inline-flex', alignItems: 'center', verticalAlign: 'middle', height: '30px' }}>Ask Fiscale AI</span>
          </MenuItem>
          <hr />
          <MenuItem icon={<LogoutIcon />} onClick={signOut} style={{ alignItems: 'bottom'}}>
            Sign Out
          </MenuItem>
          </div>
        </Menu>
        {isModalOpen && <PopupModal onClose={handleCloseModal} />}
        </div>
      </Sidebar>
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/revenue" element={<Revenue />} />
          <Route path="/expenses" element={<Expenses />} />
          <Route path="/employees" element={<Employees />} />
          <Route path="/integrations" element={<Integrations />} />
          <Route path="/fundraising" element={<Fundraising />} />
        </Routes>
      </main>
    </div>
    )}
    </Authenticator>
  );
}

export default withAuthenticator(App);
