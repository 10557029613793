import React, { useEffect, useState } from 'react'
import { usePlaidLink } from 'react-plaid-link';

import { API, Auth } from 'aws-amplify';
import { listCompanies } from '../graphql/queries'
import './Settings.css'

import qbLogo from '../assets/qb_logo.png';
import xeroLogo from '../assets/xero_logo.png';
import stripeLogo from '../assets/stripe_logo.jpeg';
import shopifyLogo from '../assets/shopify_logo.png';
import bankLogo from '../assets/bank_logo.png';
import gustoLogo from '../assets/gusto_logo.png';

import { Table, TableHead, TableRow, TableCell, TableBody, Paper, IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { Grid, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { Settings } from '@mui/icons-material';

export default function Integrations() {
  const [username, setUsername] = useState("");
  const [linkToken, setLinkToken] = useState("");
  const [publicToken, setPublicToken] = useState("");

  Auth.currentSession()
    .then((data) => {
      setUsername(data['idToken']['payload']['cognito:username']);
       // this data has user details in accessToken
    }).catch(err => console.log(err));

  const bankAccountsData = [
    { id: 1, name: 'Savings Account', dateAdded: '2023-07-01' },
    { id: 2, name: 'Checking Account', dateAdded: '2023-07-15' },
    { id: 3, name: 'Investment Account', dateAdded: '2023-07-20' },
  ];

  const integrationOptions = [
    {
      id: 1,
      title: 'Bank Account',
      logo: bankLogo,
      active: true,
      description: 'Connect your bank using the Plaid APIs, which comply with banking regulations, to pull transactions data to model your expenses and build your financial profile. This data is refreshed every 24 hours.',
    },
    {
      id: 2,
      title: 'Stripe',
      logo: stripeLogo,
      active: true,
      description: 'Connect your Stripe account to pull order, subscription and revenue data to build your metrics dashboards. We do not use any PII data. This data is refreshed every 24 hours.',
    },
    {
      id: 3,
      title: 'Quickbooks',
      logo: qbLogo,
      active: false,
      description: 'Connect your Quickbooks account to import your revenue and expenses data to complete your financial profile and start modeling scenarios. This data is refreshed every 24 hours.',
    },
    {
      id: 4,
      title: 'Xero',
      logo: xeroLogo,
      active: false,
      description: 'Connect your Xero account to import your revenue and expenses data to complete your financial profile and start modeling scenarios. This data is refreshed every 24 hours.',
    },
    {
      id: 5,
      title: 'Shopify',
      logo: shopifyLogo,
      active: false,
      description: 'Connect your Shopify account to pull product and order data. This will help us build your eCommerce profile and accurately reflect your Cost of Goods Sold (COGS) to help you plan right.',
    },
    {
      id: 6,
      title: 'Gusto',
      logo: gustoLogo,
      active: false,
      description: 'Connect your Gusto account to sync with employee and HR data. This will help you with effective headcount planning and reflect these numbers in your forecasts.',
    },
    // Add more integration options as needed
  ];

  const IntegrationCard = ({ logo, title, description, active }) => (
    <Card>
      <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
        <img style={{ height: '100px', width: '100px', margin: '10px' }} src={logo} />
      </div>
      <CardContent>
        <Typography variant="h5" align="center">
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center" marginBottom='10px'>
          {description}
        </Typography>
        <Button variant="contained" fullWidth
          onClick={
              title === 'Bank Account'
                ? () => open()
                : null // Add similar conditions for other integration options
            }
            disabled={ !active }>
          { active ? 'Connect' : 'Coming Soon' }
        </Button>
      </CardContent>
    </Card>
  );

  const IntegrationOptionsGrid = () => (
    <Grid container spacing={2}>
      {integrationOptions.map((integration) => (
        <Grid item xs={12} sm={6} md={4} key={integration.id}>
          <IntegrationCard
            logo={integration.logo}
            title={integration.title}
            description={integration.description}
            active={integration.active}
          />
        </Grid>
      ))}
    </Grid>
  );

  const handleDelete = (id) => {
    // TODO: Implement the logic to delete the entry with the given ID
    // For example: dispatch a delete action or update the state
  };

  const generateToken = async () => {
    console.log("here1: " + username);
    const apiName = 'REST';
    const path = '/plaid/linktoken/create';

    const myInit = { // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {  // OPTIONAL
          username: username,
      },
    };

    API
      .get(apiName, path, myInit)
      .then(response => {
        setLinkToken(response.data.link_token);
      })
      .catch(error => {
        console.log(error);
     });
   };

   useEffect(() => {
     if(username != "") {
       generateToken();
     }
   }, [username]);


   const exchangeToken = (public_token) => {
     console.log("here2: " + public_token + "  " + username);
     const apiName = 'REST';
     const path = '/plaid/token/exchange';

     const myInit = { // OPTIONAL
       headers: {}, // OPTIONAL
       response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
       queryStringParameters: {  // OPTIONAL
           username: username,
           public_token: public_token,
       },
     };

     API
       .get(apiName, path, myInit)
       .then(response => {
         console.log(response);
       })
       .catch(error => {
         console.log(error);
      });
  };

  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess: (public_token, metadata) => {
      // send public_token to server
      console.log(public_token);
      if(public_token != "") {
        exchangeToken(public_token);
      }
    },
  });

  return (
    <div>
      <IntegrationOptionsGrid />
      {/*<h3>Bank Accounts</h3>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ backgroundColor: '#f0f0f0' }}>Name</TableCell>
              <TableCell style={{ backgroundColor: '#f0f0f0' }}>Date Added</TableCell>
              <TableCell style={{ backgroundColor: '#f0f0f0' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bankAccountsData.map((account) => (
              <TableRow key={account.id} style={{ height: 20 }}>
                <TableCell>{account.name}</TableCell>
                <TableCell>{account.dateAdded}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleDelete(account.id)} aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Button variant="contained" color="primary" style={{ marginTop: "10px" }} onClick={() => open()} disabled={!ready}>
      Add Account
    </Button>*/}
    </div>
  )
}
