import React, { useState, useEffect, useRef } from 'react';
import { Modal, Paper, TextField, Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Grid from '@material-ui/core/Grid';

const PopupModal = ({ onClose }) => {
  const [inputText, setInputText] = useState('');
  const [messageHistory, setMessageHistory] = useState([]);
  const [modalHeight, setModalHeight] = useState(600); // Initial height for the modal
  const modalRef = useRef(null);
  const messageContainerRef = useRef(null);
  const maxModalHeight = window.innerHeight * 0.8; // Maximum height of 80% of the viewport height

  const [showGraphs, setShowGraphs] = useState(false);
  const [graphs, setGraphs] = useState(null);

  const financialMetricsData = {
    revenueData: [
     { month: 'Aug', forecast: 50000, revised_forecast: 50000 },
     { month: 'Sep', forecast: 55000, revised_forecast: 57500 },
     { month: 'Oct', forecast: 60500, revised_forecast: 66125 },
     { month: 'Nov', forecast: 65550, revised_forecast: 76043 },
     { month: 'Dec', forecast: 73205, revised_forecast: 87450 },
   ],
    runwayData: [
      { month: 'Aug', forecast: 50000, revised_forecast: 50000 },
      { month: 'Sep', forecast: 38000, revised_forecast: 42000 },
      { month: 'Oct', forecast: 30000, revised_forecast: 33000 },
      { month: 'Nov', forecast: 18000, revised_forecast: 23000 },
      { month: 'Dec', forecast: 9000, revised_forecast: 14000 },
      { month: 'Jan', forecast: 0, revised_forecast: 8000 },
      { month: 'Jan', forecast: 0, revised_forecast: 0 },
    ],
  };

  // Function to handle the text input
  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  // Function to handle API calls to ChatGPT (you need to implement this separately)
  const handleAPICall = async () => {
    if (!inputText.trim()) {
      return; // Don't submit empty messages
    }

    // Add the user's message to the history
    const userMessage = { text: inputText, sender: 'user' };
    setMessageHistory((prevHistory) => [...prevHistory, userMessage]);

    // Implement your API call to ChatGPT here using 'inputText' state
    // You can add the response to the message history after receiving the response from the API
    // const apiResponse = await fetchChatGptResponse(inputText); // Replace 'fetchChatGptResponse' with your API call function
    // const chatGptMessage = { text: apiResponse, sender: 'chatgpt' };
    // setMessageHistory((prevHistory) => [...prevHistory, chatGptMessage]);

    setShowGraphs(true);

    setInputText('');
  };

  // Scroll to the bottom of the message container whenever it updates
  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  }, [messageHistory]);

  const createGraphs = async () => {
    if(showGraphs == true) {
      setGraphs(
        <div>
          <Grid container spacing={10} width="100%">
            <Grid item xs={5}>
              <div className="chart-box">
                <h5>Revenue Projections</h5>
                <ResponsiveContainer width="100%" height={200}>
                  <LineChart data={financialMetricsData.revenueData} margin={{ top: 20, right: 30, bottom: 20, left: 20 }}>
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="forecast" stroke="#8884d8" strokeWidth={2} dot={false} name="Current Forecast" />
                    <Line
                      type="monotone"
                      dataKey="revised_forecast"
                      stroke="#8884d8"
                      strokeWidth={2}
                      dot={false}
                      strokeDasharray="5 5"
                      name="Revised Forecast"
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </Grid>
            <Grid item xs={5}>
              <div className="chart-box">
                <h5>Runway Projections</h5>
                <ResponsiveContainer width="100%" height={200}>
                  <LineChart data={financialMetricsData.runwayData} margin={{ top: 10, right: 10, bottom: 10, left: 10 }}>
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="forecast" stroke="#8884d8" strokeWidth={2} dot={false} name="Current Forecast" />
                    <Line
                      type="monotone"
                      dataKey="revised_forecast"
                      stroke="#8884d8"
                      strokeWidth={2}
                      dot={false}
                      strokeDasharray="5 5"
                      name="Revised Forecast"
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </Grid>
          </Grid>
          <p>If you grow 15% month over month, up from 10%, your runway will extend from 5 months to 6 months</p>
        </div>
      );
    }
  }
  useEffect(() => {
    createGraphs();
  }, [showGraphs]);

  // Calculate the modal height based on the number of messages
  useEffect(() => {
    const calculateModalHeight = () => {
      // Calculate the total height of messages
      let totalHeight = 0;
      messageHistory.forEach((message) => {
        const tempDiv = document.createElement('div');
        tempDiv.style.height = 'auto';
        tempDiv.innerText = message.text;
        messageContainerRef.current.appendChild(tempDiv);
        const height = tempDiv.getBoundingClientRect().height;
        messageContainerRef.current.removeChild(tempDiv);
        //totalHeight += height; <---- REVERT TO this
        totalHeight += 200;
      });

      // Calculate the new modal height
      const newModalHeight = Math.min(maxModalHeight, totalHeight + 400); // Minimum height + total height of messages

      // Set the modal height
      setModalHeight(newModalHeight);
    };

    calculateModalHeight();
  }, [messageHistory, maxModalHeight]);

  // Function to handle Enter key press
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAPICall();
    }
  };

  return (
    <Modal open={true} onClose={onClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Paper
        ref={modalRef}
        sx={{
          width: 800,
          height: modalHeight,
          p: 2,
          borderRadius: 4,
          boxShadow: 4,
          overflow: 'hidden', // Always hide the overflow, as the modal height will be adjusted dynamically
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
          <h3>Ask Fiscale AI</h3>
          <Button className="close-btn" onClick={onClose}>
            &times;
          </Button>
        </div>
        <span style={{ fontSize: '15px', fontWeight: 'bold' }}>I can create scenarios, help you answer questions about your finances or impact to them if you make a certain decision</span>
        <hr />
        <div
          ref={messageContainerRef}
          style={{
            flexGrow: 1,
            overflowY: 'auto', // Enable scrolling when conversation height exceeds the maximum modal height
            marginBottom: 10,
          }}
        >
          {messageHistory.map((message, index) => (
            <div key={index} style={{ textAlign: message.sender === 'user' ? 'left' : 'right', margin: 5 }}>
              {message.text}
              <div>
                {graphs}
              </div>
            </div>
          ))}
        </div>
        <div style={{ marginTop: 10 }}>
          <TextField
            fullWidth
            variant="outlined"
            label="Ask me anything..."
            placeholder="Type your message here..."
            value={inputText}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown} // Adding the event listener for the Enter key
          />
        </div>
        <div style={{ marginTop: 10, textAlign: 'right' }}>
          <Button variant="contained" color="primary" onClick={handleAPICall}>
            <SendIcon />
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

export default PopupModal;
